.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  text-align: center;
  font-size: 21px;
  /* Increase font size */
  border: 3px solid green;
  /* Default to green for low */
}

/* Dynamic border colors for different levels */
.popup-content.High {
  border-color: red;
}

.popup-content.Medium {
  border-color: orange;
}

.popup-content.Low {
  border-color: green;
}

.level-text.High {
  color: red;
  font-size: inherit;
  font-weight: bold;
  /* This will inherit the font size from the parent h3 */
}

.level-text.Medium {
  color: orange;
  font-size: inherit;
  font-weight: bold;
  /* Inherit font size from h3 */
}

.level-text.Low {
  color: green;
  font-size: inherit;
  font-weight: bold;
  /* Inherit font size from h3 */
}

/* Style for the "X" button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  /* Adjust font size */
  cursor: pointer;
  color: #db0909;
  /* X color */
}

.close-button:hover {
  color: red;
  /* Change color on hover */
}

.sd-element__title span {
  font-size: var(--sjs-font-questiontitle-size, var(--sjs-font-size, 18px));
  line-height: calc(1.5 * (var(--sjs-font-questiontitle-size, var(--sjs-font-size, 16px))));
}

.sd-element__title span {
  font-size: 16px;
  font-weight: 700;
  color: #161616;
}

.sd-question__header--location-top {
  padding-left: 8px;
  padding-bottom: 8px;
}

.item_text {
  font-size: 16px;
  font-weight: 800;
  color: #161616;
}

.list-item_subtext {
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .sd-dropdown__filter-string-input {
    font-size: 1em;
  }

  .sd-dropdown__value {
    font-size: 0.85em;
  }

  .sv-popup--confirm-delete .sv-popup__body-content {
    min-width: unset !important;
    /* Remove the min-width specifically for mobile view */
  }

  /* .sd-panel__content{
    overflow: hidden;
  }
  .sd-table.sd-matrixdynamic {
    max-width: 80% !important ;
  } */
}

.sd-dropdown__value .list-item_subtext {
  display: none;
}

p a {
  font-weight: bold;
}
.wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.bullet-points {
  border: 2px solid #000;
  /* Adjust color and thickness as needed */
  padding: 20px;
  /* Space inside the border */
  border-radius: 8px;
  /* Optional: for rounded corners */
  list-style-type: disc;
  /* Or any other list style type */
  margin: 0;
  /* Remove default margin */
  padding-left: 20px;
  /* Adjust according to your layout */
}


.bullet-points li {
  margin-bottom: 10px;
  /* Adjust space between bullet points as needed */
  font-size: 20px;
}

.survey-component {
  border: 1px solid #fff;
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.header img {
  max-height: 50px;
  /* Adjust as needed */
}

.title {
  font-size: 42px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-left: 20px;
  color: rgb(29, 29, 31);
  font-style: italic;
  font-weight: 300;
  word-spacing: -2px;
  line-height: 1.05;
  letter-spacing: -0.015em;
}

.title span {
  font-weight: 600;
  font-style: normal;
}

.rounded-border div:first-child {
  border-radius: 16px;
}

.sd-table th {
  padding-bottom: 0;
  text-align: left;
}

.sd-table th span {
  font-size: 12px;
  font-weight: 600;
  color: #616161;
}

th.sd-table__cell {
  padding: 0 24px;
  border-bottom: 1px solid #e5e5e5;
}

.sd-row {
  margin-top: 64px;
}

.disclaimer {
  font-size: 14px;
  font-style: italic;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  ;
  padding: 20px;
  color: #414144;
}

/* Mobile view: Stack matrix items vertically */
@media (max-width: 1280px) {
  .header {
    justify-content: space-around;
  }

  .header img {
    max-height: 30px;
    /* Adjust as needed */
  }

  .title {
    font-size: 30px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 10%;
    color: #6e6e73;
  }
}

@media (max-width: 992px) {
  .header {
    width: 80% !important;
    justify-content: space-around;
  }

  .header img {
    max-height: 30px;
    /* Adjust as needed */
  }

  .title {
    font-size: 28px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 18%;
    color: #5e5e5e;
  }
}

@media (max-width: 767px) {
  .header {
    justify-content: space-around;
  }

  .header img {
    max-height: 20px;
    /* Adjust as needed */
  }

  .title {
    font-size: 21px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-left: 15%;
    color: #5e5e5e;
  }
}